import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { myContext } from '../../../provider'
import { AllArticle } from '../../components/articles/index-articles'
import BackgroundIndex from '../../images/index-background.png';

import IndexLineBlack from '../../images/svg/index-line-black.svg';
// Blog
import Article1 from '../../images/blog/article1.png';
import Article2 from '../../images/blog/article2.png';
import Article3 from '../../images/blog/article3.png';

const BlogPage = ({ data }) => {
  console.log(data)
  return (
      <main>
        <div className="bg-gray-900 lg:overflow-hidden relative" style={{  
            backgroundImage: "url(" + BackgroundIndex + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
            }}>
          <div className="mx-auto">
            <div className="">
              <div className="mx-auto md:items-center md:px-0">
                <div className="md:py-24 md:pl-20">
                  <h1 className="text-comorant lg:text-7xl text-6xl tracking-tight text-center text-white mb-6 pt-44 md:pt-0 md:px-0 px-14 uppercase">
                    <span className="block">BLOG</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* NEW ARTICLE SECTION */}
      
        <div className="mx-auto px-6 lg:px-8 mb-20 mt-40 relative">
              <div className='text-comorant text-left text-5xl uppercase'>
                Recent Articles
              </div>
              <div className='line-index-black'>
                <img src={IndexLineBlack} alt="" />
              </div>
            </div>

            <div className='lg:grid grid-cols-3 items-center lg:flex-row'>

            { data.gogobackend.reccent.map((node, i) => (
              <div className={`event-card bg-bown-light ${i == 0 ? 'mb-8' : ''} ${i == 1 ? 'lg:mx-4 lg:mt-40 mx-0 mt-0 mb-8' : ''} ${i == 2 ? 'lg:mt-20 mt-0 mb-8' : ''}`} key={i}>
                <Link to={`/article/${node.slug}`}>
                  <div>
                    <div className='mb-4'>

                      <div class="container-image-blog">
                        <div class="image-blog" style={{  
                          backgroundImage: "url(https://api.clubmella.com/storage/images_disk/" + node.image + ")",
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat'
                          }}>
                          {/* <img src={`https://api.clubmella.com/storage/images_disk/${node.image != null ? node.image : "" }`}/> */}
                        </div>
                        <div class="corner-top"></div>
                        <div class="corner-top-in-left"></div>
                        <div class="corner-top-in-right"></div>
                        
                        <div class="corner-bottom"></div>
                        <div class="corner-bottom-in-left"></div>
                        <div class="corner-bottom-in-right"></div>
                      </div>

                      
                    </div>
                    <div className='text-3xl text-comorant'>
                    {node.title_en}
                    </div>
                  </div>
                </Link>
              </div>
            ))}

          </div>


        <div className="lg:grid grid-cols-3 items-center lg:flex-row">
            <AllArticle data={data.gogobackend.all}/>
        </div>
      </main>
  )
}

export const queryPrimary = graphql`
query {
  gogobackend {
      all: news_articles_content(
        status: "Published"
        limit: 20
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          author_slug
          image,
          created_at
      }
      reccent: news_articles_content(
        status: "Published"
        limit: 3
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          author_slug
          image,
          created_at
      }
    }
  }
`

export default BlogPage